<template>
  <div>
    <el-form inline>
      <el-form-item>
        <el-input v-model="keyword" placeholder="支持名称和代码搜索"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchEnterprises">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="enterprises" style="width: 100%">
      <el-table-column prop="Name" label="企业名称"></el-table-column>
      <el-table-column prop="Code" label="企业统一社会信用代码"></el-table-column>
      <el-table-column prop="Address" label="企业地址"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editEnterprise(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="deleteEnterprise(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
     <!--工具条-->
     <el-col :span="24" class="toolbar">
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20"
        :total="total" :current-page="page" style="float:right;"></el-pagination>
    </el-col>

    <el-dialog
      title="编辑企业信息"
      :visible.sync="editModalVisible"
      width="30%"
    >
      <el-form :model="currentEnterprise">
        <el-form-item label="企业名称">
          <el-input v-model="currentEnterprise.name"></el-input>
        </el-form-item>
        <el-form-item label="企业统一社会信用代码">
          <el-input v-model="currentEnterprise.code"></el-input>
        </el-form-item>
        <el-form-item label="企业地址">
          <el-input v-model="currentEnterprise.address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editModalVisible = false">取消</el-button>
        <el-button type="primary" @click="updateEnterprise">更新</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'App',
  data() {
    return {
      keyword: '',
      enterprises: [],
      currentEnterprise: {},
      editModalVisible: false,
      page:1,
      total:0,
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.fetchEnterprises();
    },
    fetchEnterprises() {
      this.axios
        .post("/delivery/enterpriselist", { customerid: this.customerid, keyword: this.keyword, page: this.page })
        .then(res => {
          this.enterprises = res.data.values.rows;
          this.total = res.data.values.count;
        });
    },
    editEnterprise(enterprise) {
      this.currentEnterprise = {
        id: enterprise.Id,
        name: enterprise.Name,
        code: enterprise.Code,
        address: enterprise.Address
      };
      this.editModalVisible = true;
    },
    deleteEnterprise(enterprise) {
      this.enterprises = this.enterprises.filter(item => item.Id !== enterprise.Id);
      this.axios
        .post("/delivery/deleteenterprise", { customerid: this.customerid, id: enterprise.Id })
        .then(_ => {
          this.$message.success("删除成功");
        });
    },
    updateEnterprise() {
      const index = this.enterprises.findIndex(item => item.Id === this.currentEnterprise.id);
      if (index !== -1) {
        this.enterprises.splice(index, 1, { Id: this.currentEnterprise.id, Name: this.currentEnterprise.name, Code: this.currentEnterprise.code, Address: this.currentEnterprise.address });
      }

      this.axios
        .post("/delivery/updateenterprise", { customerid: this.customerid, ...this.currentEnterprise })
        .then(_ => {
          this.$message.success("修改成功");
          this.editModalVisible = false;
        });
    }
  }
};
</script>

<style>
.dialog-footer {
  text-align: right;
}
</style>